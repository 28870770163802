/**
 * FAMFAMFAM flag icons CSS.
 *
 * Examples:
 * <i class="famfamfam-flag-fr"> France</i>
 * <i class="famfamfam-flag-us"> United States</i>
 */

[class^="famfamfam-flag"] {
  display: inline-block;
  width: 16px;
  height: 11px;
  line-height: 11px;
  /* vertical-align: text-top; */
  background-image: url("famfamfam-flags.png");
  background-position: 0 0;
  background-repeat: no-repeat;
}

.famfamfam-flag-zw { background-position: 0px 0px; width: 16px; height: 11px; }
.famfamfam-flag-zm { background-position: -16px 0px; width: 16px; height: 11px; }
.famfamfam-flag-za { background-position: 0px -11px; width: 16px; height: 11px; }
.famfamfam-flag-yt { background-position: -16px -11px; width: 16px; height: 11px; }
.famfamfam-flag-ye { background-position: -32px 0px; width: 16px; height: 11px; }
.famfamfam-flag-ws { background-position: -32px -11px; width: 16px; height: 11px; }
.famfamfam-flag-wf { background-position: 0px -22px; width: 16px; height: 11px; }
.famfamfam-flag-wales { background-position: -16px -22px; width: 16px; height: 11px; }
.famfamfam-flag-vu { background-position: -32px -22px; width: 16px; height: 11px; }
.famfamfam-flag-vn { background-position: 0px -33px; width: 16px; height: 11px; }
.famfamfam-flag-vi { background-position: -16px -33px; width: 16px; height: 11px; }
.famfamfam-flag-vg { background-position: -32px -33px; width: 16px; height: 11px; }
.famfamfam-flag-ve { background-position: -48px 0px; width: 16px; height: 11px; }
.famfamfam-flag-vc { background-position: -48px -11px; width: 16px; height: 11px; }
.famfamfam-flag-va { background-position: -48px -22px; width: 16px; height: 11px; }
.famfamfam-flag-uz { background-position: -48px -33px; width: 16px; height: 11px; }
.famfamfam-flag-uy { background-position: 0px -44px; width: 16px; height: 11px; }
.famfamfam-flag-us { background-position: -16px -44px; width: 16px; height: 11px; }
.famfamfam-flag-um { background-position: -16px -44px; width: 16px; height: 11px; }
.famfamfam-flag-ug { background-position: -32px -44px; width: 16px; height: 11px; }
.famfamfam-flag-ua { background-position: -48px -44px; width: 16px; height: 11px; }
.famfamfam-flag-tz { background-position: -64px 0px; width: 16px; height: 11px; }
.famfamfam-flag-tw { background-position: -64px -11px; width: 16px; height: 11px; }
.famfamfam-flag-tv { background-position: -64px -22px; width: 16px; height: 11px; }
.famfamfam-flag-tt { background-position: -64px -33px; width: 16px; height: 11px; }
.famfamfam-flag-tr { background-position: -64px -44px; width: 16px; height: 11px; }
.famfamfam-flag-to { background-position: 0px -55px; width: 16px; height: 11px; }
.famfamfam-flag-tn { background-position: -16px -55px; width: 16px; height: 11px; }
.famfamfam-flag-tm { background-position: -32px -55px; width: 16px; height: 11px; }
.famfamfam-flag-tl { background-position: -48px -55px; width: 16px; height: 11px; }
.famfamfam-flag-tk { background-position: -64px -55px; width: 16px; height: 11px; }
.famfamfam-flag-tj { background-position: 0px -66px; width: 16px; height: 11px; }
.famfamfam-flag-th { background-position: -16px -66px; width: 16px; height: 11px; }
.famfamfam-flag-tg { background-position: -32px -66px; width: 16px; height: 11px; }
.famfamfam-flag-tf { background-position: -48px -66px; width: 16px; height: 11px; }
.famfamfam-flag-td { background-position: -64px -66px; width: 16px; height: 11px; }
.famfamfam-flag-tc { background-position: -80px 0px; width: 16px; height: 11px; }
.famfamfam-flag-sz { background-position: -80px -11px; width: 16px; height: 11px; }
.famfamfam-flag-sy { background-position: -80px -22px; width: 16px; height: 11px; }
.famfamfam-flag-sx { background-position: -80px -33px; width: 16px; height: 11px; }
.famfamfam-flag-sv { background-position: -80px -44px; width: 16px; height: 11px; }
.famfamfam-flag-st { background-position: -80px -55px; width: 16px; height: 11px; }
.famfamfam-flag-ss { background-position: -80px -66px; width: 16px; height: 11px; }
.famfamfam-flag-sr { background-position: 0px -77px; width: 16px; height: 11px; }
.famfamfam-flag-so { background-position: -16px -77px; width: 16px; height: 11px; }
.famfamfam-flag-sn { background-position: -32px -77px; width: 16px; height: 11px; }
.famfamfam-flag-sm { background-position: -48px -77px; width: 16px; height: 11px; }
.famfamfam-flag-sl { background-position: -64px -77px; width: 16px; height: 11px; }
.famfamfam-flag-sk { background-position: -80px -77px; width: 16px; height: 11px; }
.famfamfam-flag-si { background-position: -96px 0px; width: 16px; height: 11px; }
.famfamfam-flag-sh { background-position: -96px -11px; width: 16px; height: 11px; }
.famfamfam-flag-sg { background-position: -96px -22px; width: 16px; height: 11px; }
.famfamfam-flag-se { background-position: -96px -33px; width: 16px; height: 11px; }
.famfamfam-flag-sd { background-position: -96px -44px; width: 16px; height: 11px; }
.famfamfam-flag-scotland { background-position: -96px -55px; width: 16px; height: 11px; }
.famfamfam-flag-sc { background-position: -96px -66px; width: 16px; height: 11px; }
.famfamfam-flag-sb { background-position: -96px -77px; width: 16px; height: 11px; }
.famfamfam-flag-sa { background-position: 0px -88px; width: 16px; height: 11px; }
.famfamfam-flag-rw { background-position: -16px -88px; width: 16px; height: 11px; }
.famfamfam-flag-ru { background-position: -32px -88px; width: 16px; height: 11px; }
.famfamfam-flag-rs { background-position: -48px -88px; width: 16px; height: 11px; }
.famfamfam-flag-ro { background-position: -64px -88px; width: 16px; height: 11px; }
.famfamfam-flag-qa { background-position: -80px -88px; width: 16px; height: 11px; }
.famfamfam-flag-py { background-position: -96px -88px; width: 16px; height: 11px; }
.famfamfam-flag-pw { background-position: 0px -99px; width: 16px; height: 11px; }
.famfamfam-flag-pt { background-position: -16px -99px; width: 16px; height: 11px; }
.famfamfam-flag-ps { background-position: -32px -99px; width: 16px; height: 11px; }
.famfamfam-flag-pr { background-position: -48px -99px; width: 16px; height: 11px; }
.famfamfam-flag-pn { background-position: -64px -99px; width: 16px; height: 11px; }
.famfamfam-flag-pm { background-position: -80px -99px; width: 16px; height: 11px; }
.famfamfam-flag-pl { background-position: -96px -99px; width: 16px; height: 11px; }
.famfamfam-flag-pk { background-position: -112px 0px; width: 16px; height: 11px; }
.famfamfam-flag-ph { background-position: -112px -11px; width: 16px; height: 11px; }
.famfamfam-flag-pg { background-position: -112px -22px; width: 16px; height: 11px; }
.famfamfam-flag-pf { background-position: -112px -33px; width: 16px; height: 11px; }
.famfamfam-flag-pe { background-position: -112px -44px; width: 16px; height: 11px; }
.famfamfam-flag-pa { background-position: -112px -55px; width: 16px; height: 11px; }
.famfamfam-flag-om { background-position: -112px -66px; width: 16px; height: 11px; }
.famfamfam-flag-nz { background-position: -112px -77px; width: 16px; height: 11px; }
.famfamfam-flag-nu { background-position: -112px -88px; width: 16px; height: 11px; }
.famfamfam-flag-nr { background-position: -112px -99px; width: 16px; height: 11px; }
.famfamfam-flag-no { background-position: 0px -110px; width: 16px; height: 11px; }
.famfamfam-flag-bv { background-position: 0px -110px; width: 16px; height: 11px; }
.famfamfam-flag-sj { background-position: 0px -110px; width: 16px; height: 11px; }
.famfamfam-flag-nl { background-position: -16px -110px; width: 16px; height: 11px; }
.famfamfam-flag-ni { background-position: -32px -110px; width: 16px; height: 11px; }
.famfamfam-flag-ng { background-position: -48px -110px; width: 16px; height: 11px; }
.famfamfam-flag-nf { background-position: -64px -110px; width: 16px; height: 11px; }
.famfamfam-flag-ne { background-position: -80px -110px; width: 16px; height: 11px; }
.famfamfam-flag-nc { background-position: -96px -110px; width: 16px; height: 11px; }
.famfamfam-flag-na { background-position: -112px -110px; width: 16px; height: 11px; }
.famfamfam-flag-mz { background-position: -128px 0px; width: 16px; height: 11px; }
.famfamfam-flag-my { background-position: -128px -11px; width: 16px; height: 11px; }
.famfamfam-flag-mx { background-position: -128px -22px; width: 16px; height: 11px; }
.famfamfam-flag-mw { background-position: -128px -33px; width: 16px; height: 11px; }
.famfamfam-flag-mv { background-position: -128px -44px; width: 16px; height: 11px; }
.famfamfam-flag-mu { background-position: -128px -55px; width: 16px; height: 11px; }
.famfamfam-flag-mt { background-position: -128px -66px; width: 16px; height: 11px; }
.famfamfam-flag-ms { background-position: -128px -77px; width: 16px; height: 11px; }
.famfamfam-flag-mr { background-position: -128px -88px; width: 16px; height: 11px; }
.famfamfam-flag-mq { background-position: -128px -99px; width: 16px; height: 11px; }
.famfamfam-flag-mp { background-position: -128px -110px; width: 16px; height: 11px; }
.famfamfam-flag-mo { background-position: 0px -121px; width: 16px; height: 11px; }
.famfamfam-flag-mn { background-position: -16px -121px; width: 16px; height: 11px; }
.famfamfam-flag-mm { background-position: -32px -121px; width: 16px; height: 11px; }
.famfamfam-flag-ml { background-position: -48px -121px; width: 16px; height: 11px; }
.famfamfam-flag-mk { background-position: -64px -121px; width: 16px; height: 11px; }
.famfamfam-flag-mh { background-position: -80px -121px; width: 16px; height: 11px; }
.famfamfam-flag-mg { background-position: -96px -121px; width: 16px; height: 11px; }
.famfamfam-flag-me { background-position: 0px -132px; width: 16px; height: 12px; }
.famfamfam-flag-md { background-position: -112px -121px; width: 16px; height: 11px; }
.famfamfam-flag-mc { background-position: -128px -121px; width: 16px; height: 11px; }
.famfamfam-flag-ma { background-position: -16px -132px; width: 16px; height: 11px; }
.famfamfam-flag-ly { background-position: -32px -132px; width: 16px; height: 11px; }
.famfamfam-flag-lv { background-position: -48px -132px; width: 16px; height: 11px; }
.famfamfam-flag-lu { background-position: -64px -132px; width: 16px; height: 11px; }
.famfamfam-flag-lt { background-position: -80px -132px; width: 16px; height: 11px; }
.famfamfam-flag-ls { background-position: -96px -132px; width: 16px; height: 11px; }
.famfamfam-flag-lr { background-position: -112px -132px; width: 16px; height: 11px; }
.famfamfam-flag-lk { background-position: -128px -132px; width: 16px; height: 11px; }
.famfamfam-flag-li { background-position: -144px 0px; width: 16px; height: 11px; }
.famfamfam-flag-lc { background-position: -144px -11px; width: 16px; height: 11px; }
.famfamfam-flag-lb { background-position: -144px -22px; width: 16px; height: 11px; }
.famfamfam-flag-la { background-position: -144px -33px; width: 16px; height: 11px; }
.famfamfam-flag-kz { background-position: -144px -44px; width: 16px; height: 11px; }
.famfamfam-flag-ky { background-position: -144px -55px; width: 16px; height: 11px; }
.famfamfam-flag-kw { background-position: -144px -66px; width: 16px; height: 11px; }
.famfamfam-flag-kr { background-position: -144px -77px; width: 16px; height: 11px; }
.famfamfam-flag-kp { background-position: -144px -88px; width: 16px; height: 11px; }
.famfamfam-flag-kn { background-position: -144px -99px; width: 16px; height: 11px; }
.famfamfam-flag-km { background-position: -144px -110px; width: 16px; height: 11px; }
.famfamfam-flag-ki { background-position: -144px -121px; width: 16px; height: 11px; }
.famfamfam-flag-kh { background-position: -144px -132px; width: 16px; height: 11px; }
.famfamfam-flag-kg { background-position: 0px -144px; width: 16px; height: 11px; }
.famfamfam-flag-ke { background-position: -16px -144px; width: 16px; height: 11px; }
.famfamfam-flag-jp { background-position: -32px -144px; width: 16px; height: 11px; }
.famfamfam-flag-jo { background-position: -48px -144px; width: 16px; height: 11px; }
.famfamfam-flag-jm { background-position: -64px -144px; width: 16px; height: 11px; }
.famfamfam-flag-je { background-position: -80px -144px; width: 16px; height: 11px; }
.famfamfam-flag-it { background-position: -96px -144px; width: 16px; height: 11px; }
.famfamfam-flag-is { background-position: -112px -144px; width: 16px; height: 11px; }
.famfamfam-flag-ir { background-position: -128px -144px; width: 16px; height: 11px; }
.famfamfam-flag-iq { background-position: -144px -144px; width: 16px; height: 11px; }
.famfamfam-flag-io { background-position: -160px 0px; width: 16px; height: 11px; }
.famfamfam-flag-in { background-position: -160px -11px; width: 16px; height: 11px; }
.famfamfam-flag-im { background-position: -160px -22px; width: 16px; height: 9px; }
.famfamfam-flag-il { background-position: -160px -31px; width: 16px; height: 11px; }
.famfamfam-flag-ie { background-position: -160px -42px; width: 16px; height: 11px; }
.famfamfam-flag-id { background-position: -160px -53px; width: 16px; height: 11px; }
.famfamfam-flag-hu { background-position: -160px -64px; width: 16px; height: 11px; }
.famfamfam-flag-ht { background-position: -160px -75px; width: 16px; height: 11px; }
.famfamfam-flag-hr { background-position: -160px -86px; width: 16px; height: 11px; }
.famfamfam-flag-hn { background-position: -160px -97px; width: 16px; height: 11px; }
.famfamfam-flag-hk { background-position: -160px -108px; width: 16px; height: 11px; }
.famfamfam-flag-gy { background-position: -160px -119px; width: 16px; height: 11px; }
.famfamfam-flag-gw { background-position: -160px -130px; width: 16px; height: 11px; }
.famfamfam-flag-gu { background-position: -160px -141px; width: 16px; height: 11px; }
.famfamfam-flag-gt { background-position: 0px -155px; width: 16px; height: 11px; }
.famfamfam-flag-gs { background-position: -16px -155px; width: 16px; height: 11px; }
.famfamfam-flag-gr { background-position: -32px -155px; width: 16px; height: 11px; }
.famfamfam-flag-gq { background-position: -48px -155px; width: 16px; height: 11px; }
.famfamfam-flag-gp { background-position: -64px -155px; width: 16px; height: 11px; }
.famfamfam-flag-gn { background-position: -80px -155px; width: 16px; height: 11px; }
.famfamfam-flag-gm { background-position: -96px -155px; width: 16px; height: 11px; }
.famfamfam-flag-gl { background-position: -112px -155px; width: 16px; height: 11px; }
.famfamfam-flag-gi { background-position: -128px -155px; width: 16px; height: 11px; }
.famfamfam-flag-gh { background-position: -144px -155px; width: 16px; height: 11px; }
.famfamfam-flag-gg { background-position: -160px -155px; width: 16px; height: 11px; }
.famfamfam-flag-ge { background-position: -176px 0px; width: 16px; height: 11px; }
.famfamfam-flag-gd { background-position: -176px -11px; width: 16px; height: 11px; }
.famfamfam-flag-gb { background-position: -176px -22px; width: 16px; height: 11px; }
.famfamfam-flag-ga { background-position: -176px -33px; width: 16px; height: 11px; }
.famfamfam-flag-fr { background-position: -176px -44px; width: 16px; height: 11px; }
.famfamfam-flag-gf { background-position: -176px -44px; width: 16px; height: 11px; }
.famfamfam-flag-re { background-position: -176px -44px; width: 16px; height: 11px; }
.famfamfam-flag-mf { background-position: -176px -44px; width: 16px; height: 11px; }
.famfamfam-flag-bl { background-position: -176px -44px; width: 16px; height: 11px; }
.famfamfam-flag-fo { background-position: -176px -55px; width: 16px; height: 11px; }
.famfamfam-flag-fm { background-position: -176px -66px; width: 16px; height: 11px; }
.famfamfam-flag-fk { background-position: -176px -77px; width: 16px; height: 11px; }
.famfamfam-flag-fj { background-position: -176px -88px; width: 16px; height: 11px; }
.famfamfam-flag-fi { background-position: -176px -99px; width: 16px; height: 11px; }
.famfamfam-flag-fam { background-position: -176px -110px; width: 16px; height: 11px; }
.famfamfam-flag-eu { background-position: -176px -121px; width: 16px; height: 11px; }
.famfamfam-flag-et { background-position: -176px -132px; width: 16px; height: 11px; }
.famfamfam-flag-es { background-position: -176px -143px; width: 16px; height: 11px; }
.famfamfam-flag-er { background-position: -176px -154px; width: 16px; height: 11px; }
.famfamfam-flag-england { background-position: 0px -166px; width: 16px; height: 11px; }
.famfamfam-flag-eh { background-position: -16px -166px; width: 16px; height: 11px; }
.famfamfam-flag-eg { background-position: -32px -166px; width: 16px; height: 11px; }
.famfamfam-flag-ee { background-position: -48px -166px; width: 16px; height: 11px; }
.famfamfam-flag-ec { background-position: -64px -166px; width: 16px; height: 11px; }
.famfamfam-flag-dz { background-position: -80px -166px; width: 16px; height: 11px; }
.famfamfam-flag-do { background-position: -96px -166px; width: 16px; height: 11px; }
.famfamfam-flag-dm { background-position: -112px -166px; width: 16px; height: 11px; }
.famfamfam-flag-dk { background-position: -128px -166px; width: 16px; height: 11px; }
.famfamfam-flag-dj { background-position: -144px -166px; width: 16px; height: 11px; }
.famfamfam-flag-de { background-position: -160px -166px; width: 16px; height: 11px; }
.famfamfam-flag-cz { background-position: -176px -166px; width: 16px; height: 11px; }
.famfamfam-flag-cy { background-position: 0px -177px; width: 16px; height: 11px; }
.famfamfam-flag-cx { background-position: -16px -177px; width: 16px; height: 11px; }
.famfamfam-flag-cw { background-position: -32px -177px; width: 16px; height: 11px; }
.famfamfam-flag-cv { background-position: -48px -177px; width: 16px; height: 11px; }
.famfamfam-flag-cu { background-position: -64px -177px; width: 16px; height: 11px; }
.famfamfam-flag-cs { background-position: -80px -177px; width: 16px; height: 11px; }
.famfamfam-flag-cr { background-position: -96px -177px; width: 16px; height: 11px; }
.famfamfam-flag-co { background-position: -112px -177px; width: 16px; height: 11px; }
.famfamfam-flag-cn { background-position: -128px -177px; width: 16px; height: 11px; }
.famfamfam-flag-cm { background-position: -144px -177px; width: 16px; height: 11px; }
.famfamfam-flag-cl { background-position: -160px -177px; width: 16px; height: 11px; }
.famfamfam-flag-ck { background-position: -176px -177px; width: 16px; height: 11px; }
.famfamfam-flag-ci { background-position: -192px 0px; width: 16px; height: 11px; }
.famfamfam-flag-cg { background-position: -192px -11px; width: 16px; height: 11px; }
.famfamfam-flag-cf { background-position: -192px -22px; width: 16px; height: 11px; }
.famfamfam-flag-cd { background-position: -192px -33px; width: 16px; height: 11px; }
.famfamfam-flag-cc { background-position: -192px -44px; width: 16px; height: 11px; }
.famfamfam-flag-catalonia { background-position: -192px -55px; width: 16px; height: 11px; }
.famfamfam-flag-ca { background-position: -192px -66px; width: 16px; height: 11px; }
.famfamfam-flag-bz { background-position: -192px -77px; width: 16px; height: 11px; }
.famfamfam-flag-by { background-position: -192px -88px; width: 16px; height: 11px; }
.famfamfam-flag-bw { background-position: -192px -99px; width: 16px; height: 11px; }
.famfamfam-flag-bt { background-position: -192px -110px; width: 16px; height: 11px; }
.famfamfam-flag-bs { background-position: -192px -121px; width: 16px; height: 11px; }
.famfamfam-flag-br { background-position: -192px -132px; width: 16px; height: 11px; }
.famfamfam-flag-bq { background-position: -192px -143px; width: 16px; height: 11px; }
.famfamfam-flag-bo { background-position: -192px -154px; width: 16px; height: 11px; }
.famfamfam-flag-bn { background-position: -192px -165px; width: 16px; height: 11px; }
.famfamfam-flag-bm { background-position: -192px -176px; width: 16px; height: 11px; }
.famfamfam-flag-bj { background-position: 0px -188px; width: 16px; height: 11px; }
.famfamfam-flag-bi { background-position: -16px -188px; width: 16px; height: 11px; }
.famfamfam-flag-bh { background-position: -32px -188px; width: 16px; height: 11px; }
.famfamfam-flag-bg { background-position: -48px -188px; width: 16px; height: 11px; }
.famfamfam-flag-bf { background-position: -64px -188px; width: 16px; height: 11px; }
.famfamfam-flag-be { background-position: -80px -188px; width: 16px; height: 11px; }
.famfamfam-flag-bd { background-position: -96px -188px; width: 16px; height: 11px; }
.famfamfam-flag-bb { background-position: -112px -188px; width: 16px; height: 11px; }
.famfamfam-flag-ba { background-position: -128px -188px; width: 16px; height: 11px; }
.famfamfam-flag-az { background-position: -144px -188px; width: 16px; height: 11px; }
.famfamfam-flag-ax { background-position: -160px -188px; width: 16px; height: 11px; }
.famfamfam-flag-aw { background-position: -176px -188px; width: 16px; height: 11px; }
.famfamfam-flag-au { background-position: -192px -188px; width: 16px; height: 11px; }
.famfamfam-flag-hm { background-position: -192px -188px; width: 16px; height: 11px; }
.famfamfam-flag-at { background-position: -208px 0px; width: 16px; height: 11px; }
.famfamfam-flag-as { background-position: -208px -11px; width: 16px; height: 11px; }
.famfamfam-flag-ar { background-position: -208px -22px; width: 16px; height: 11px; }
.famfamfam-flag-ao { background-position: -208px -33px; width: 16px; height: 11px; }
.famfamfam-flag-an { background-position: -208px -44px; width: 16px; height: 11px; }
.famfamfam-flag-am { background-position: -208px -55px; width: 16px; height: 11px; }
.famfamfam-flag-al { background-position: -208px -66px; width: 16px; height: 11px; }
.famfamfam-flag-ai { background-position: -208px -77px; width: 16px; height: 11px; }
.famfamfam-flag-ag { background-position: -208px -88px; width: 16px; height: 11px; }
.famfamfam-flag-af { background-position: -208px -99px; width: 16px; height: 11px; }
.famfamfam-flag-ae { background-position: -208px -110px; width: 16px; height: 11px; }
.famfamfam-flag-ad { background-position: -208px -121px; width: 16px; height: 11px; }
.famfamfam-flag-np { background-position: -208px -132px; width: 9px; height: 11px; }
.famfamfam-flag-ch { background-position: -208px -143px; width: 11px; height: 11px; }
